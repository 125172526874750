//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import companyNotice from "../companyNotice";
import cookie from "@/storage/cookies.js";
import { apiHome } from "@/api/apiHome.js";
import env from "../../../../../../env";
import EventBus from "@/util/event-bus";
import { SlickList, SlickItem } from "vue-slicksort";
let rect = require("@/assets/home/rect.png");
let rectOrange = require("@/assets/home/rect_orange.png");
export default {
  data() {
    return {
      rect,
      rectOrange,
      userTypeList: [],
      loading: true,
      icon: {
        0: "cy",
        1: "hy",
        2: "tj",
        3: "gz"
      },
      autoPlay: true,
      carouselIndex: 0,
      obj: {
        0: {
          list: [
            {
              name: "邀请成员",
              url: "inviceCy"
            }
          ]
        },
        1: {
          list: [
            {
              name: "邀请会员",
              url: "inviceHy"
            },
            {
              name: "加盟会员",
              url: "joinHy"
            }
          ]
        },
        2: {
          list: []
        },
        3: {
          list: [
            {
              name: "企业大全",
              url: "allCompany"
            }
          ]
        }
      },
      dialogVisible: false,
      moveList: [],
      chooseList: [
        {
          name: "会员企业",
          companyType: 1
        },
        {
          name: "成员企业",
          companyType: 0
        },
        {
          name: "关注企业",
          companyType: 3
        }
      ]
    };
  },
  components: {
    companyNotice,
    SlickList,
    SlickItem
  },
  filters: {
    setRemake(val) {
      switch (val) {
        case 0: // 成员
          return "您还没有成员企业，快去邀请吧！";
          break;
        case 1: // 会员
          return "您还没有会员企业，快去邀请会员或者加盟会员吧！";
          break;
        case 2: // 推荐
          return "暂无推荐企业";
          break;
        case 3: // 关注
          return "您还没有关注其他企业，快去看看吧！";
          break;
        default:
          return " ";
          break;
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isCompany() {
      return this.$store.state.user.type === 1;
    }
  },
  methods: {
    closeDialog() {
      console.log("我是否触发", 123);
      this.dialogVisible = false;
      this.moveList = [];
    },
    // 保存排序
    async submit() {
      let list = this.moveList.map(item => {
        return item.companyType;
      });
      let params = {
        companyTypes: list
      };
      let res = await apiHome.saveCompanyIndex(params);
      if (true) {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.getlist();
        setTimeout(() => {
          // this.dialogVisible = false
          this.closeDialog();
        }, 500);
      }
    },
    // 计算返回类名
    chooseHandler(item) {
      // return true
      return this.moveList.some((ele, index) => ele.name === item.name);
    },
    // 点击选择
    chooseItem(item) {
      console.log("chooseItem", item);
      // if()
      if (this.moveList.some((ele, index) => ele.name === item.name)) {
        // let index = this.moveList.find(item=>item.name)
        let index = 0;
        for (let i = 0; i < this.moveList.length; i++) {
          if (this.moveList[i]["name"] === item["name"]) {
            index = i;
            break;
          }
        }
        this.moveList.splice(index, 1);
      } else {
        this.moveList.push(item);
      }
    },
    menuChange(res) {
      let moveList = JSON.parse(JSON.stringify(this.moveList));
    },
    async setSequence() {
      this.moveList = [];
      try {
        let res = await apiHome.getCompanyTypeIndex();
        this.moveList = res.data.data.companyTypes.map(item => {
          return {
            name: item.companyTypeName + "企业",
            companyType: item.companyType
          };
        });
        // console.log("res", res.data.data.companyTypes);
        // if (res && !res.data.data.companyTypes.length) {
        //   this.moveList.push({
        //     name: "推荐企业",
        //     companyType: 2
        //   });
        // } else {
        //   this.moveList = res.data.data.companyTypes.map((item) => {
        //     return {
        //       name: item.companyTypeName + '企业',
        //       companyType: item.companyType
        //     };
        //   });
        // }
        this.dialogVisible = true;
      } catch (error) {
        console.log(error);
      }
      // this.setSequence
    },
    more() {
      let activeIndex = this.$refs["demoforani"].activeIndex || 0;
      let key = this.userTypeList[activeIndex]["key"]; // key: 0, name: "成员 key: 1, name: "会员 key: 2, name: "推荐", key: 3, name: "关注"
      let obj = {
        0: "/companyType?type=cy",
        1: "/companyType?type=hy",
        2: "/companyType?type=tj",
        3: "/companyType?type=gz"
      };
      let path = obj[key];
      this.$router.push({ path });
    },
    async getlist() {
      // 获取列表
      this.userTypeList = [];
      this.loading = true;
      let currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : "";
      let params = {
        areaCode: currentArea ? currentArea.id : 650000,
        businessType: 1 //
      };
      try {
        let res = await apiHome.getAllIndexList(params);
        let { code, data, msg } = res.data;
        if (+code === 200) {
          this.loading = false;
          this.userTypeList = data;
          this.$set(this.userTypeList[0], "isActive", true);
          this.setActiveItemFn(0);
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    setActiveItemFn(item) {
      // 设置跑马灯
      // console.log(
      //   'this.$refs["demoforani"]',
      //   this.$refs["demoforani"],
      //   this.$refs["demoforani"].timer
      // );
      this.$refs["demoforani"].setActiveItem(item);
      this.autoPlay = false;
      setTimeout(() => {
        this.carouselIndex = item;
        this.autoPlay = true;
      });
      this.$refs["demoforani"].$forceUpdate();
    },
    carouselChange(item) {
      // console.log("item", item);
      //
      if (!this.userTypeList || !this.userTypeList.length) {
        return;
      } else {
        for (let i = 0; i < this.userTypeList.length; i++) {
          this.$set(this.userTypeList[i], "isActive", false);
        }
        this.$set(this.userTypeList[item], "isActive", true);
      }
    },
    go2Detail(data) {
      // 按钮操作
      let url_pers = env[process.env.environment].VUE_APP_PERSONURL; //用户中心
      let url_rpoute = env[process.env.environment].qiye_RPOUTE_API; //企业服务
      let { url } = data;
      let path = "";
      switch (url) {
        case "inviceCy": // 邀请成员
          path = `${url_pers}/enterprise_invitationMember`;
          window.open(path);
          break;
        case "allCompany": // 企业大全
          path = `${url_rpoute}/businessHall`;
          window.open(path);
          break;
        case "inviceHy": // 邀请会员
          EventBus.$emit("joinCompany", { joinType: 0 });
          break;
        case "joinHy": // 申请加盟会员
          EventBus.$emit("joinCompany", { joinType: 1 });
          break;
        default:
          break;
      }
      return;
    }
  },
  created() {},
  watch: {
    user: {
      handler(val) {
        console.log(val, "user");
        this.getlist();
      },
      immediate: true
    }
  }
};
