//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    load: {
      type: Boolean,
    },
    type: {
      // 0 模块下第一个类型 1 第二个类型
      default: 0,
      type: Number,
    },
    moduleType: {
      // 模块 jicai zhaocai 。。。
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {
    list: {
      handler: function () {
        // this.resetList();
        let list = JSON.parse(JSON.stringify(this.list));
        // console.log('this.showList', list.splice(0, 10))
        this.showList = list.splice(0, 10);
      },
      immediate: true,
    },
  },
  methods: {
    /**@name 鼠标移入走马灯item时触发的事件 */
    mouseInItem(e) {
      const domArr = e.target.getElementsByClassName("item_top");
      Array.from(domArr).forEach((el) => {
        el.style.webkitAnimationPlayState = "paused";
      });
    },
    /**@name 鼠标移出item触发的事件 */
    mouseOutItem(e) {
      const domArr = e.target.getElementsByClassName("item_top");
      Array.from(domArr).forEach((el) => {
        el.style.webkitAnimationPlayState = "running";
      });
    },
    todetail(id) {
      this.$emit("todetail", {
        moduleType: this.moduleType,
        type: this.type,
        id: id,
      });
    },
    // resetList() {
    // let listLength = this.list.length;
    // let currentIndex = 0;
    // if (listLength > 4) {
    // clearInterval(this.timer);
    // this.timer = setInterval(() => {
    // //   this.showList.splice(0, 1);
    // //   if (listLength - currentIndex > 4) {
    // //     this.showList.push(this.list[currentIndex + 4]);
    // //     currentIndex += 1;
    // //   } else {
    // //     this.showList.push(this.list[0]);
    // //     currentIndex = 0;
    // //   }
    // // }, 5000);
    // } else {
    //   clearInterval(this.timer);
    // }
    // },
    // destroyed() {
    //   clearInterval(this.timer);
    // },
  },
};
