//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import { apiResSearch } from "@/api/apiResSearch";
import env from "@/../env.js";
import cookie from "@/storage/cookies.js";
import apiScrollList from "@/api/apiScrollList";
import companyType from "./compantType/index";
import List from "../volunteerTeam/List.vue";
import NoticeItemScoll from "./NoticeItemScoll";
export default {
  data: function() {
    return {
      tagList1: [{ name: "招标大厅" }, { name: "直接竞价" }],
      tagList2: [{ name: "集采大厅" }, { name: "需方集采" }],
      tagList3: [{ name: "出租租赁" }, { name: "求租大厅" }],
      tagList4: [{ name: "货车大厅" }, { name: "货运大厅" }],
      tagList5: [{ name: "工人找活" }, { name: "找工人" }],
      tagList6: [{ name: "代办服务" }, { name: "专业团队" }],
      activeTag1: 0,
      activeTag2: 0,
      activeTag3: 0,
      activeTag4: 0,
      activeTag5: 0,
      activeTag6: 0,
      noticeList1: [],
      noticeList2: [],
      noticeList3: [],
      noticeList4: [],
      noticeList5: [],
      noticeList6: [],
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
      loading5: true,
      loading6: true,
      domains: {
        zhaocai: env[process.env.environment].VUE_APP_TENDERURL,
        jicai: env[process.env.environment].VUE_APP_GROUNPBUYING,
        zulin: env[process.env.environment].VUE_APP_RENTALMARKET,
        wuliu: env[process.env.environment].VUE_APP_LOGISTICSURL,
        gongdui: env[process.env.environment].VUE_APP_TEAM_URL,
        qifu: env[process.env.environment].qiye_SERVICE,
        shangpin: env[process.env.environment].VUE_APP_MATERIALSURL
      },
      isEmpty1: false,
      isEmpty2: false,
      isEmpty3: false,
      isEmpty4: false,
      isEmpty5: false,
      isEmpty6: false
    };
  },
  watch: {},
  props: {},
  computed: {
    userInfo: function() {
      return this.$store.state.user || {};
    }
  },
  components: {
    NoticeItemScoll,
    companyType
    // List
    // CompanyNotice: () => import("./companyNotice/index.vue"),
  },
  async fetch() {
    // 招标大厅
    const parmas = {
      page: 1,
      type: "",
      limit: 10,
      sortMethod: "1",
      queryPosition: 1
    };
    const p1 = apiHome
      .getTenderList(parmas)
      .then(res => {
        const { result, code, msg } = res.data;
        if (+code === 0) {
          this.noticeList1 = result || [];
          this.isEmpty1 = this.noticeList1.length == 0;
          this.loading1 = false;
        }
      })
      .catch(() => {
        return Promise.resolve();
      });
    let currentArea = cookie.cookieRead("area")
      ? JSON.parse(cookie.cookieRead("area"))
      : "";
    const parmas2 = {
      provinceNo: "",
      cityNo: "",
      status: "",
      firstType: "",
      searchKey: "",
      current: 1,
      limit: 10
    };
    const p2 = apiResSearch
      .getGroupSaleList(parmas2)
      .then(res => {
        const { data, code, msg } = res.data;
        if (+code === 200) {
          this.noticeList2 = data.records;
          this.isEmpty2 = this.noticeList2.length == 0;
          this.loading2 = false;
        }
      })
      .catch(() => {
        return Promise.resolve();
      });

    // const parma3 = {
    //   areaNos: [],
    //   cityNo: "",
    //   current: 1,
    //   limit: 10,
    //   provinceNo: currentArea.id,
    //   sortWay: 1,
    //   status: 1,
    //   title: "",
    //   type: "",
    // };
    // const p3 = apiScrollList
    //   .getListRentSeekXQ(parma3)
    //   .then((res) => {
    //     const { data, code, msg } = res.data;
    //     if (+code === 200) {
    //       this.noticeList3 = data.records;
    //       this.loading3 = false;
    //     }
    //   })
    //   .catch(() => {
    //     return Promise.resolve();
    //   });
    const p3 = apiScrollList
      .getRentJixieList({
        sortWay: 1,
        pageSize: 10,
        pageNum: 1,
        provinceNo: currentArea ? currentArea.id : 650000
      })
      .then(res => {
        const { data, code, msg } = res.data;
        if (+code === 200) {
          this.noticeList3 = data.records;
          this.isEmpty3 = this.noticeList3.length == 0;
          this.loading3 = false;
        }
      })
      .catch(() => {
        return Promise.resolve();
      });
    const p4 = apiHome
      .getCarOwner({
        carLenNos: [],
        carTypeNos: [],
        cityNo: "",
        destCityNo: "",
        limit: 10,
        page: 1,
        radius: "",
        timeSort: 1
      })
      .then(res => {
        const { data, code, msg } = res.data;
        if (+code === 200) {
          const arr =
            data &&
            data.records &&
            data.records.map(item => {
              const nweName = `${item.carNumber}/${item.carTypeName}/${item.carLenName}/${item.weight}吨`;
              return {
                ...item,
                name: nweName
              };
            });
          this.noticeList4 = arr;
          this.isEmpty4 = this.noticeList4.length == 0;
          this.loading4 = false;
        }
      })
      .catch(() => {
        return Promise.resolve();
      });
    const parmas5 = {
      areaNoList: [],
      cityNo: "",
      employmentStatus: "",
      limit: 10,
      page: 1,
      provinceNo: currentArea ? currentArea.id : 650000
    };
    const p5 = apiHome
      .getLaborList(parmas5)
      .then(res => {
        const { data, code, msg } = res.data;
        if (+code === 200) {
          const list = data.records || [];
          this.noticeList5 = list;
          this.isEmpty5 = this.noticeList5.length == 0;
          this.loading5 = false;
          return;
        }
      })
      .catch(() => {
        return Promise.resolve();
      });

    const p6 = apiHome
      .getServiceRecommendList()
      .then(res => {
        const { data, code, msg } = res.data;
        if (+code === 0) {
          const list = data || [];
          this.noticeList6 = list.map(item => {
            item.name = item.serviceName;
            return item;
          });
          this.isEmpty6 = this.noticeList6.length == 0;
          this.loading6 = false;
          return;
        }
        console.log("获取代办服务列表", res);
      })
      .catch(() => {
        return Promise.resolve();
      });

    return new Promise(res => {
      Promise.all([p1, p2, p3, p4, p5, p6])
        .then(() => {
          res();
        })
        .catch(() => {
          res();
        });
    });
  },
  methods: {
    getCompanyServer(val) {
      this.loading6 = true;
      if (+val === 0) {
        apiHome
          .getServiceRecommendList()
          .then(res => {
            console.log("获取代办服务列表", res);
            const { data, code, msg } = res.data;
            if (+code === 0) {
              const list = data || [];
              this.noticeList6 = list.map(item => {
                item.name = item.serviceName;
                return item;
              });
              this.isEmpty6 = this.noticeList6.length == 0;
              this.loading6 = false;
              return;
            }
          })
          .catch(() => {
            // return Promise.resolve();
            this.noticeList6 = [];
            this.loading6 = false;
          });
      } else {
        console.log(val);
        apiHome
          .getTeamList()
          .then(res => {
            const { data, code, msg } = res.data;
            if (+code === 200) {
              const list = data.records || [];
              this.noticeList6 = list;
              this.isEmpty6 = this.noticeList6.length == 0;
              this.loading6 = false;
              return;
            }
          })
          .catch(() => {
            // return Promise.resolve();
            this.noticeList6 = [];
            this.loading6 = false;
          });
      }
    },
    async getGongDuiList(val) {
      let currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : "";
      // 获取劳务工队工人找活的数据
      this.loading5 = true;
      this.noticeList5 = [];
      if (+val === 0) {
        const parmas5 = {
          areaNoList: [],
          cityNo: "",
          employmentStatus: "",
          limit: 10,
          page: 1,
          provinceNo: currentArea ? currentArea.id : 650000
        };
        apiHome
          .getLaborList(parmas5)
          .then(res => {
            const { data, code, msg } = res.data;
            if (+code === 200) {
              const list = data.records || [];
              this.noticeList5 = list;
              this.isEmpty5 = this.noticeList5.length == 0;
              this.loading5 = false;
              return;
            }
          })
          .catch(() => {
            this.loading5 = false;
          });
      } else {
        const parmas = {
          areaNoList: [],
          cityNo: "",
          provinceNo: currentArea ? currentArea.id : 650000,
          pageSize: 10,
          pageNum: 1
        };
        try {
          let res = await apiHome.getRecommendWorker(parmas);
          const { data, code, msg } = res.data;
          if (+code === 200) {
            const list = data.records || [];
            this.noticeList5 = list;
            this.isEmpty5 = this.noticeList5.length == 0;
            this.loading5 = false;
            return;
          }
        } catch (error) {
          this.loading5 = false;
        }
      }
    },
    async getGroupSaleList(val) {
      // 获取集采供方发起的数据
      if (+val === 0) {
        const parmas2 = {
          provinceNo: "",
          cityNo: "",
          firstType: "",
          searchKey: "",
          current: 1,
          limit: 10,
          status: ""
        };
        let res = await apiResSearch.getGroupSaleList(parmas2);
        const { data, code, msg } = res.data;
        if (+code === 200) {
          this.noticeList2 = data.records;
          this.isEmpty2 = this.noticeList2.length == 0;
          this.loading2 = false;
        }
      } else {
        // 集采大厅
        const res = (await apiScrollList.getJicaiList()) || {};
        const { code, data } = res.data;
        if (+code === 200) {
          this.noticeList2 = data.records;
          this.isEmpty2 = this.noticeList2.length == 0;
          this.loading2 = false;
        }
        this.loading2 = false;
      }
    },

    tomore(res) {
      console.log("1");
      let { moduleType, type } = res;
      let obj = {
        zhaocai: {
          0: "/zb", // 招标大厅
          1: "/direct-jb" // 直接竞价
        },
        jicai: {
          0: "/jiCaiHall", //集采大厅
          1: "/jiCaiLobby" // 需方发起
        },
        zulin: {
          0: "/both", // 出租大厅
          1: "/demand" // 需求大厅
        },
        wuliu: {
          0: "/trunk?active=2", // 货车大厅
          1: "/trunk?active=1" // 货运大厅
        },
        gongdui: {
          0: "/FlexibleEmployment", // 临时用工
          1: "/worker" //专业工人
        },
        qifu: {
          0: "/serve", // 服务大厅
          1: "/team" // 专业团队
        }
      };
      let keyList = Object.keys(obj);
      let str = "";
      for (let i = 0; i < keyList.length; i++) {
        if (keyList[i] === moduleType) {
          let keyobj = obj[moduleType];
          let keyLists = Object.keys(keyobj);
          console.log(keyobj, type, keyLists);
          for (let j = 0; j < keyLists.length; j++) {
            console.log("keyList[j] == type", keyLists[j], type);
            if (keyLists[j] == type) {
              str = this.domains[moduleType] + keyobj[keyLists[j]];
            }
          }
        }
      }
      // console.log("str", str);
      window.open(str);
    },
    todetail(res) {
      let item = res.id;
      console.log('item', item)
      if (this.activeTag1 === 0) {
        // 招标大厅详情
        const zhaobiao = env[process.env.environment].VUE_APP_TENDERURL;
        const { id, state, businessType } = item;
        if (+businessType === 2) {
          window.open(
            `${zhaobiao}/CompeteBiddingDetail?no=${item.id}&typeName=${item.typeName}`
          );
          return;
        }
        if (+businessType === 3) {
          const { id, no, type } = item;
          console.log('type', type)
          if (type === 4) { // 工程项目
            window.open(`${zhaobiao}/demandNoticeDetail?id=${id}`);
          } else if (type === 3) {
            // 商品采购
            window.open(`${zhaobiao}/companyDemandDetail?no=${id}`);
          } else if (type == 20) {
            window.open(`${zhaobiao}/companyDemandDetail?no=${id}&type=8`);
          } else if (type == 19) {
            //物流货运
            window.open(`${zhaobiao}/companyDemandDetail?no=${id}&type=9`);
          } else if (type == 2) {
            // 劳务用工
            window.open(`${zhaobiao}/companyDemandDetail?no=${id}&type=10`);
          } else {
            // 租赁
            window.open(`${zhaobiao}/companyDemandDetail?no=${id}`);
          }
          return;
        }
        if (item.dataSources === 2) {
          window.open(`${zhaobiao}/tenderDetails?id=${id}`);
          return;
        } else {
          if (+state === 8) {
            // 待报名
            window.open(`${zhaobiao}/biddingDetail?id=${id}`);
          } else {
            window.open(`${zhaobiao}/hotTenderMachineLaborDetail?no=${id}`);
          }
          return;
        }
      }
      if (this.activeTag1 === 1) {
        // 直接竞价详情
        const zhaobiao = env[process.env.environment].VUE_APP_TENDERURL;
        window.open(
          `${zhaobiao}/CompeteBiddingDetail?no=${item.id}&typeName=${item.typeName}`
        );
        return;
      }
      // 集采大厅详情
      // const jicai = env[process.env.environment].VUE_APP_GROUNPBUYING;
      // window.open(`${jicai}/fastDetails?id=${item.no}&groupbuyClass=1`);
    },
    // 租赁/服务/施工工队详情
    jicaiDetail(res) {
      let { moduleType, id } = res;
      let activeType = res.type;
      let {
        zhaocai,
        jicai,
        zulin,
        wuliu,
        gongdui,
        qifu,
        shangpin
      } = this.domains;
      let str = "";
      console.log(moduleType, activeType, id);
      switch (moduleType) {
        case "jicai": {
          let { no, groupBuyType, groupbuyClass } = id;
          if (+activeType === 0) {
            str = `${jicai}/factoryDetail/${no}`;
          } else {
            if (+groupBuyType === 1) {
              // 1 即报集采 2 汇集采购
              str = `${jicai}/fastDetails?id=${no}&groupbuyClass=${groupbuyClass}`;
            } else if (+groupBuyType === 2) {
              str = `${jicai}/collectDetails?id=${no}&groupbuyClass=${groupbuyClass}`;
            } else {
              str = "";
            }
          }
          break;
        }
        case "zulin": {
          let { no, type, dataId } = id;
          
          // 出租大厅
          if (+activeType === 0) {
            switch (type * 1) {
            case 1: // 工程机械
              str = `${zulin}/rentalMachineDetails?no=${no}`;
              break;
            case 2: // 工程设备
              str = `${zulin}/rentalDetails/?id=${dataId}&no=${dataId}`;
              break;
            case 3: // 车辆租赁
              str = `${zulin}/carsRentalDetail?id=${dataId}`;
              break;
            case 4: // 房产租赁
              str = `${zulin}/HousesLeaseDetails?id=${dataId}`;
              break;
            case 5: // 土地租赁
              str = `${zulin}/landDetails?id=${dataId}`;
              break;
            case 6: // 工厂租赁
              str = `${zulin}/factoryDetails?id=${dataId}`;
              break;
            case 7: // 农机租赁
              str = `${zulin}/farmMachineryDetails?id=${dataId}`;
              break;
            default:
              str = `${zulin}/customLeaseDetails?no=${dataId}`
          }
            // let obj = {
            //   1: "rentalMachineDetails/?no=",
            //   2: "rentalDetails/?id=",
            //   3: "carsRentalDetail/?id=",
            //   4: "HousesLeaseDetails/?id=",
            //   5: "landDetails/?id=",
            //   6: "factoryDetails/?id=",
            //   7: "farmMachineryDetails/?id="
            // };
            // str = `${zulin}/${obj[+type]}${no}`;
            // if (+type === 2) {
            //   str = `${zulin}/${obj[+type]}${no}&no=${no}`;
            // }
            // 求租大厅
          } else {
            // str = `${zulin}/DemandHallDetail?id=${no}&type=${type - 1}`;
            str = `${zulin}/DemandHallDetail?id=${dataId}&type=${type - 1}`;
          }
          break;
        }
        case "wuliu": {
          let { no } = id;
          if (+activeType === 0) {
            str = `${wuliu}/DriverDetail?id=${no}`;
          } else {
            str = `${wuliu}/CargoDetails?CargoId=${no}&typeOf=1`;
          }
          break;
        }
        case "gongdui": {
          // FlexibleDetail?no=1427532588276387840
          let { no } = id;
          if (+activeType === 0) {
            str = `${gongdui}/FlexibleDetail?no=${no}`;
          } else {
            str = `${gongdui}/workerDetail?no=${no}`;
          }
          break;
        }
        case "qifu": {
          let { serviceHallId, teamNo } = id;
          // console.log(id)
          // return
          // let ids = id.id;
          if (+activeType === 0) {
            str = `${qifu}/serveDetail?no=${serviceHallId}&typeId=`;
          } else {
            str = `${shangpin}/teamWebsite?no=${teamNo}`;
          }
          break;
        }
        default:
          str = "";
          break;
      }
      str && window.open(str);
      // if (this.activeTag2 === 0) {
      //   // 租赁经营详情
      //   const zulin = env[process.env.environment].VUE_APP_RENTALMARKET;
      //   let openUrl = "";
      //   switch (item.type * 1) {
      //     case 1: // 工程机械
      //       openUrl = `${zulin}/rentalMachineDetails?no=${item.no}`;
      //       break;
      //     case 2: // 工程设备
      //       openUrl = `${zulin}/rentalDetails/?id=${item.dataId}&no=${item.dataId}`;
      //       break;
      //     case 3: // 车辆租赁
      //       openUrl = `${zulin}/carsRentalDetail?id=${item.no}`;
      //       break;
      //     case 4: // 房产租赁
      //       openUrl = `${zulin}/HousesLeaseDetails?id=${item.dataId}`;
      //       break;
      //     case 5: // 土地租赁
      //       openUrl = `${zulin}/landDetails?id=${item.dataId}`;
      //       break;
      //     case 6: // 工厂租赁
      //       openUrl = `${zulin}/factoryDetails?id=${item.dataId}`;
      //       break;
      //     case 7: // 农机租赁
      //       openUrl = `${zulin}/farmMachineryDetails?id=${item.dataId}`;
      //       break;
      //     default:
      //   }
      //   window.open(openUrl);
      //   return;
      // }
      // if (this.activeTag2 === 1) {
      //   // 服务经营详情
      //   const fuwu = env[process.env.environment].qiye_SERVICE;
      //   const { id, serviceClass } = item;
      //   window.open(`${fuwu}/serveDetail?no=${id}&typeId=${serviceClass}`);
      //   return;
      // }
      // // 施工工队详情
      // const gongdui = env[process.env.environment].VUE_APP_TEAM_URL;
      // const { companyNo, no } = item;
      // window.open(
      //   env[process.env.environment]
      //     .VUE_APP_TEAM_URL`${gongdui}/ConstructionTeamDetail?companyNo=${companyNo}&teamNo=${no}`
      // );
    },
    zulinDetail(item) {
      if (this.activeTag3 === 0) {
        // 求租需求详情
        const zulin = env[process.env.environment].VUE_APP_RENTALMARKET;
        window.open(
          `${zulin}/DemandHallDetail?id=${item.no}&type=${item.type - 1}`
        );
      }
      if (this.activeTag3 === 1) {
        // 服务需求详情
        const fuwu = env[process.env.environment].qiye_SERVICE;
        const { id, requirementTypes } = item;
        window.open(
          `${fuwu}/demandDetail?id=${id}&requirementTypes=${requirementTypes}`
        );
        return;
      }
      // 临时用工详情
      const gongdui = env[process.env.environment].VUE_APP_TEAM_URL;
      const { no } = item;
      window.open(`${gongdui}/FlexibleDetail?no=${no}`);
    },
    huocheDetail(item) {
      const wuliu = env[process.env.environment].VUE_APP_LOGISTICSURL;
      if (this.activeTag4 === 0) {
        // 车辆信息详情
        window.open(`${wuliu}/DriverDetail/?id=${item.no}`);
        return;
      }
      if (this.activeTag4 === 1) {
        // 货运信息详情
        window.open(`${wuliu}/CargoDetails?typeOf=1&CargoId=${item.no}`);
        return;
      }
      // 企业专项详情
      const jicai = env[process.env.environment].VUE_APP_GROUNPBUYING;
      window.open(`${jicai}/fastDetails?id=${item.no}&groupbuyClass=1`);
    },
    changeTag(val, type) {
      if (this.loading1 || this.loading2 || this.loading3 || this.loading4) {
        return;
      }
      this[`noticeList${type}`] = [];
      let key = `activeTag${type}`;
      this[key] = val;
      this[`loading${type}`] = true;
      switch (type) {
        case 1:
          this.isEmpty1 = false;
          this.getTagList1(val);
          break;
        case 2:
          this.isEmpty2 = false;
          this.getGroupSaleList(val);
          break;
        case 3:
          this.isEmpty3 = false;
          // this.getTagList3(val);
          this.getZuLinList(val);
          break;
        case 4:
          this.isEmpty4 = false;
          this.getTagList4(val);
          break;
        case 5:
          this.isEmpty5 = false;
          this.getGongDuiList(val);
          break;
        case 6:
          this.isEmpty6 = false;
          this.getCompanyServer(val);
          break;
        default:
          break;
      }
    },
    async getTagList1(val) {
      if (+val === 0) {
        // 招标大厅
        const parmas = {
          page: 1,
          type: "",
          limit: 10,
          sortMethod: "1",
          queryPosition: 1
        };
        let notice1 = (await apiHome.getTenderList(parmas)) || {};
        const { result, code, msg } = notice1.data;
        if (+code === 0) {
          const list = result || [];
          this.noticeList1 = list;
          this.isEmpty1 = this.noticeList1.length == 0;
        }
        this.loading1 = false;
        return;
      }
      if (+val === 1) {
        // 直接竞价
        let res = (await apiScrollList.getbidList()) || {};
        const { code, result } = res.data;
        if (+code === 0) {
          const list = result || [];
          this.noticeList1 = list;
          this.isEmpty1 = this.noticeList1.length == 0;
        }
        this.loading1 = false;
        return;
      }
    },
    // 租赁模块列表获取
    async getZuLinList(val) {
      let currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : "";
      this.loading3 = true;
      this.noticeList3 = [];
      // 获取出租列表
      if (+val === 0) {
        let res = await apiScrollList.getRentJixieList({
          sortWay: 1,
          pageSize: 10,
          pageNum: 1,
          provinceNo: currentArea ? currentArea.id : 650000
        });
        const { data, code, msg } = res.data;
        if (+code === 200) {
          this.noticeList3 = data.records;
          this.isEmpty3 = this.noticeList3.length == 0;
          this.loading3 = false;
        } else {
          this.loading3 = false;
        }
        // 获取求租列表
      } else {
        const parmas = {
          areaNos: [],
          cityNo: "",
          current: 1,
          limit: 10,
          provinceNo: currentArea.id,
          sortWay: 1,
          status: 1,
          title: "",
          type: ""
        };
        const res = (await apiScrollList.getListRentSeekXQ(parmas)) || {};
        const { data, code, msg } = res.data;
        if (+code === 200) {
          const list = data.records || [];
          this.noticeList3 = list;
          this.isEmpty3 = this.noticeList3.length == 0;
        }
        this.loading3 = false;
      }
    },
    async getTagList4(val) {
      this.noticeList4 = [];
      // 车辆信息列表
      if (val === 0) {
        const res =
          (await apiHome.getCarOwner({
            carLenNos: [],
            carTypeNos: [],
            cityNo: "",
            destCityNo: "",
            limit: 10,
            page: 1,
            radius: "",
            timeSort: 1
          })) || {};
        const { data, code, msg } = res.data;
        if (+code === 200) {
          const arr =
            data &&
            data.records &&
            data.records.map(item => {
              const nweName = `${item.carNumber}/${item.carTypeName}/${item.carLenName}/${item.weight}吨`;
              return {
                ...item,
                name: nweName
              };
            });
          this.noticeList4 = arr;
          this.isEmpty4 = this.noticeList4.length == 0;
          this.loading4 = false;
        }
        return;
      }
      // 货运信息列表
      if (val === 1) {
        const res = await apiHome.getSupplyForGoods({
          limit: 10,
          page: 1,
          pattern: 1
        });
        const { data, code, msg } = res.data;
        if (+code === 200) {
          const list = data.records;
          list &&
            list.map(
              item =>
                (item.name = `${item.cityName} ${item.districtName} -${item.destCityName} ${item.destDistrictName}`)
            );
          this.noticeList4 = list;
          this.isEmpty4 = this.noticeList4.length == 0;
        }
        this.loading4 = false;
        return;
      }
    }
  }
};
