var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notice_scroll",on:{"mouseenter":_vm.mouseInItem,"mouseleave":_vm.mouseOutItem}},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title-text"},[_vm._t("default")],2),_vm._v(" "),_c('span',{staticClass:"title-more",on:{"click":function($event){return _vm.$emit('more', { type: _vm.type, moduleType: _vm.moduleType })}}},[_vm._v("更多")])]),_vm._v(" "),(_vm.showList.length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.load),expression:"load"}],staticClass:"item_show"},[_c('div',{class:_vm.showList.length > 3 ? 'item_top' : ''},[_c('ul',[_c('transition-group',_vm._l((_vm.showList),function(item,index){return _c('li',{key:((item.id || item.no) + "_" + index),on:{"click":function($event){return _vm.todetail(item)}}},[(_vm.moduleType === 'gongdui' && +_vm.type === 1)?_c('span',{staticClass:"text-hidden"},[_vm._v("\n              "+_vm._s(item.workTypeName)+"/"+_vm._s(item.workYear + "年")+"/"+_vm._s(item.sex === 1 ? "男" : "女")+"/"+_vm._s(item.proficiencyName)+"\n            ")]):_c('span',[(
                  item.name ||
                  item.goodsName ||
                  item.title ||
                  item.rentalTitle ||
                  item.projectName ||
                  item.serviceHallName
                )?_c('span',{staticClass:"text-hidden",attrs:{"title":item.name ||
                  item.goodsName ||
                  item.title ||
                  item.rentalTitle ||
                  item.projectName ||
                  item.serviceHallName}},[_vm._v(_vm._s(item.name ||
                  item.goodsName ||
                  item.title ||
                  item.rentalTitle ||
                  item.projectName ||
                  item.serviceHallName))]):_c('span',{staticClass:"text-hidden",attrs:{"title":("" + (item.teamName))}},[_vm._v(_vm._s(("" + (item.teamName))))])])])}),0)],1)]),_vm._v(" "),_c('div',{staticClass:"item_bottom",class:_vm.showList.length > 3 ? 'item_top' : ''},[_c('ul',[_c('transition-group',_vm._l((_vm.showList),function(item,index){return _c('li',{key:((item.id || item.no) + "_" + index),on:{"click":function($event){return _vm.todetail(item)}}},[(_vm.moduleType === 'gongdui' && +_vm.type === 1)?_c('span',{staticClass:"text-hidden"},[_vm._v("\n              "+_vm._s(item.workTypeName)+"/"+_vm._s(item.workYear + "年")+"/"+_vm._s(item.sex === 1 ? "男" : "女")+"/"+_vm._s(item.proficiencyName)+"\n            ")]):_c('span',[(
                  item.name ||
                  item.goodsName ||
                  item.title ||
                  item.rentalTitle ||
                  item.projectName ||
                  item.serviceHallName
                )?_c('span',{staticClass:"text-hidden",attrs:{"title":item.name ||
                  item.goodsName ||
                  item.title ||
                  item.rentalTitle ||
                  item.projectName ||
                  item.serviceHallName}},[_vm._v(_vm._s(item.name ||
                  item.goodsName ||
                  item.title ||
                  item.rentalTitle ||
                  item.projectName ||
                  item.serviceHallName))]):_c('span',{staticClass:"text-hidden",attrs:{"title":("" + (item.teamName))}},[_vm._v(_vm._s(("" + (item.teamName))))])])])}),0)],1)])]):_vm._e(),_vm._v(" "),(!_vm.load && !_vm.showList.length)?_c('div',{staticClass:"none-data"},[_vm._v("暂无公告")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }