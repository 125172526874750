//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import env from "../../../../../../env"
import { imageCutting } from "@/util/util.js";
export default {
  data() {
    return {
      defaultImg: require('@/assets/qiyedianpu.png')
    };
  },
  created() {
    // this.initList()
  },
  props:{
    companyList:{
      type: Array,
      default(){
        return []
      }
    },
    typeName:{
      type: String,
      default: ''
    },
  },
  mounted() {},
  computed: {
  },
  watch: {
  },
  filters: {
    typeToClassName(val) {
      const classEnum = {
        1: 'hui-yuan',
        2: 'tui-jian'
      }
      if(!val) return ''
      return classEnum[val]
    }
  },
  methods: {
    go2detail(item) {
      const { id, companyNo } = item;
      const url = env[process.env.environment].VUE_APP_MATERIALSURL;
      window.open(`${url}/company/${id}?no=${companyNo}`);
    },
    imageCutting:imageCutting
  }
};
