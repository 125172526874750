//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  data: function() {
    return {};
  },
  props:{
    width:{
      type:String,
      default:'170px'
    },
    height:{
      type:String,
      default:'170px'
    },
    text:{
      type:String,
      default:'暂无数据'
    },
  },
  methods: {},
  created() {},
  mounted() {}
};
